<template>

    <main-container icon="mdi-alert" title="Não autorizado">
        <v-container
        class="text-center"
        fill-height
      >
        <v-row align="center">
          <v-col>
            <h1 class="text-h1 blue--text">Ops, 503</h1>

            <p class="text-h5 mt-4">Manutenção!</p>
            <p class="body-1">O sistema encontra-se em manutenção. Tente novamente mais tarde.</p>

          </v-col>
        </v-row>
      </v-container>
    </main-container>
</template>

<script>
export default {
    name: 'NaoAutorizado'
}
</script>